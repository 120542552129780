const firebaseConfig = {
  apiKey: 'AIzaSyDmp9kvhMuyBJBgwEwooT4rFOouw0R2qcU',
  authDomain: 'warhammer40karmybuilder.firebaseapp.com',
  databaseURL: 'https://warhammer40karmybuilder.firebaseio.com',
  projectId: 'warhammer40karmybuilder',
  storageBucket: 'warhammer40karmybuilder.appspot.com',
  messagingSenderId: '467035013706',
  appId: '1:467035013706:web:002647629cb9878a4a1cb3',
};

export default firebaseConfig;
